<template>
  <b-card>
    <div>
      <validation-observer ref="provider">
        <b-form>
          <b-row>
            <b-col md="9">
              <b-form-group label="Description" label-for="description">
                <validation-provider #default="{ errors }" name="description" rules="required">
                  <b-form-input
                    v-model="form.description"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="description"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Quarter" label-for="quarter">
                <validation-provider #default="{ errors }" name="quarter" rules="required|min_value:1|max_value:4">
                  <b-form-input
                    v-model.number="form.quarter"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="quarter"
                    rules="required|min_value:1|max_value:4"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Year" label-for="year">
                <validation-provider #default="{ errors }" name="year" rules="required">
                  <b-form-input
                    v-model.number="form.year"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="YYYY"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="1">
              <b-form-group label="Publish" label-for="isPublish">
                <validation-provider #default="{ errors }" name="isPublish">
                  <b-form-checkbox v-model="form.isPublish" type="number" :state="errors.length > 0 ? false : null" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Action Buttons -->
          <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="save">
            {{ questionId ? 'Edit' : 'Create' }}
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BFormCheckbox, BFormTextarea } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

// eslint-disable-next-line
import { required, min_value, max_value } from '@validations'
export default {
  name: 'JourneyCreate',
  components: {
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BCard,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      questionId: null,
      form: {
        year: 0,
        quarter: 1,
        isPublish: false,
        description: '',
      },
      min_value, max_value
    }
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchCardById: 'journey/fetchCardById',
      createCard: 'journey/createCard',
      updateCard: 'journey/updateCard',
    }),

    initState() {
      this.registerValidator(this.$refs.provider)
      const today = new Date()
      this.form.year = today.getFullYear()

      if (!this.$route.params.id) return

      this.questionId = this.$route.params.id
      this.getCardById(this.questionId)
    },

    create() {
      this.waitRequest(() => {
        return this.createCard(this.form)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Card is added',
                icon: 'UserPlusIcon',
                variant: 'info',
              },
            })
            this.$router.go(-1)
            this.form = null
          })
          .catch(this.checkErrors)
      })
    },

    update() {
      this.waitRequest(() => {
        return this.updateCard({ id: this.questionId, form: this.form })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Card is updated',
                icon: 'UserPlusIcon',
                variant: 'info',
              },
            })
            this.$router.go(-1)
            this.form = null
          })
          .catch(this.checkErrors)
      })
    },

    getCardById(questionId) {
      this.waitRequest(() => {
        return this.fetchCardById(questionId)
          .then(response => {
            this.form = response.data
          })
          .catch(this.checkErrors)
      })
    },

    async save() {
      const isValid = await this.checkIsValidForm()

      if (this.requestInProgress || !isValid || !this.form) return

      if (this.questionId) return this.update()

      this.create()
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
